
.FormInput {
    border: solid 1px darkgray;
    font-size: 1rem;
    padding: 0 1rem;
    box-shadow: #daa520 9px 9px 0 0;
    width: 90%;
    height: 44px;
    outline: none;
    &:disabled {
        color: darkgray;
        background: gray;
    }

    &:focus {
        border-color: black;
    }

    &.invalid {
        background-color: #f8a688;
    }
}