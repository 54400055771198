
.ButtonsWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.SearchButtonsWrap {
    justify-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .Button {
        min-width: 150px;
        margin: 0 1rem 1rem 1rem;
    }

}

.LookingForAGame {
    display: flex;
    align-items: center;
    width: 100%;
}