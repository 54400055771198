
.HelpWrap {

}


.Help {
    padding: 0 2rem 0 0;
    display: flex;
    flex-direction: column;

    .ControlsColl {
        padding-right: 5rem;
        /*min-width: 300px;*/
        margin-bottom: 2rem;
    }

    .Title {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 1rem;
    }
}

@media screen and (min-width: 1024px) {
    .Help {
        flex-direction: row;
        .ControlsColl {
            min-width: 300px;
        }
    }
}


.BlockBlock {
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
    .BlockIcon {
        height: 32px;
        width: 32px;
        min-width: 32px;
        border: solid 1px rebeccapurple;
        background-color: #008080;
        margin-right: 1rem;
    }
}