
.CharWrap {
    padding: 0 2rem;
}

.ChatInputWrap {
    display: flex;
    align-items: center;
    width: 100%;

    .InputColl {
        flex: 1;
        max-width: 400px;
        padding-right: 3rem;
    }
}

.ChatOutputWrap {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 1rem;
}

.ChatInput {
    border: solid 1px darkgray;
    font-size: 1rem;
    box-shadow: #daa520 9px 9px 0 0;
    height: 38px;
    padding: 0 0 0 1rem;
    width: 100%;
}

