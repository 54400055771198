

.AppHeader {
    padding: 1rem 0 .6rem 0;
    font-weight: bold;
    width: 100%;
    display: flex;
}

.HeaderWrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 1rem;
}

.UserBlock {
    color: black;
    padding-bottom: 1rem;

    .UserName {
        background: #ded9c6;
        font-size: 24px;
        display: flex;
        align-items: center;
        padding: .5rem 1rem;
    }

    .UserNameEditBlock {
        text-align: right;
        > div {
            display: inline-block;
            background: #ded9c6;
            padding: 0 1rem .2rem 1rem;
            color: #ef7f57;
            cursor: pointer;
            &:hover {
                color: navy;
            }
        }
    }
}


@media screen and (min-width: 1024px) {
    .HeaderWrapper {
        padding: 0;
    }
}

@media screen and (min-width: 600px) {
    .HeaderWrapper {
        flex-direction: row;
    }

    .UserBlock {
        padding: 1rem 0;
    }
}
