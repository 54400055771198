.CanvasWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
}

.Canvas {
    width: 100%;
    height: 750px;
}